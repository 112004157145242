import React from 'react';
import configData from "../../config.json";
import LastNewsMaster from '../TrangChu/LastNewsMaster';
import LastNewsFullWidth from './LastNewsFullWidth';
import Slider from "./Slider";
import {Helmet} from 'react-helmet'; 
import Banner from './Banner';

class HomeBody extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
        this.state = {dataJsCompany:{}}
	}
	componentDidMount() {
        fetch(
            configData.serviceUrl+ '/api/Common/GetCompanyInfo',
            {headers:new Headers ({
                'Access-Control-Allow-Origin' : '*'
            })}
        
          ) 
          .then(response => response.json())
          .then(dataJs => {
              console.log(dataJs);
              this.setState({
                  dataJsCompany: dataJs.Data
              });
          })
          .catch(e => {
              console.log(e);
          });
      }
	componentWillMount(){
	}
    render(){
        return (
            <div className="container-fluid ml-0 mr-0 pl-0 pr-0" style={{ marginTop:"84px" }}  >
               
                <Helmet>
                    <title></title>
                    <meta name="description" content={this.state.dataJsCompany.NOTE} />
                  </Helmet>
                 <section id="hero" className="inner-page pb-0 pt-0">
                     <Banner></Banner>
                 </section>
                 <section   className="inner-page pb-0 pt-0">
                    <LastNewsMaster ID_OTY={configData.LastNewsModule0} LeftFirst="true"/> 
                    <LastNewsMaster ID_OTY={configData.LastNewsModule} LeftFirst="false"/> 
                    <LastNewsMaster ID_OTY={configData.LastNewsModule2} LeftFirst="true"/> 
                 </section>
             </div>
        );
    }
}
export default HomeBody;
