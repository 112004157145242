import axios from 'axios';
import React  from 'react';
import configData from "../../../config.json";
import CookieService from '../../../services/CookieService';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ImageView from '../../Commons/ImageView';

import { Editor, OriginalTools } from 'react-bootstrap-editor';
import {  Container, Row,Col, Form, Button } from 'react-bootstrap';


class ChiTietSanPham extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                dataJs: {},
                imageUrl:props.row.TEXT1,
                imageBackgroudUrl: props.row.TEXT7,
                selectedFile: null,
                selectedBgFile:null
              }
          }
          componentDidMount() {
            
            

            if(this.props.NO_OBJ===0)
            {
                this.setState({
                    dataJs: this.props.row
                });
            }
            else
            {
            fetch(
                configData.serviceUrl+ '/api/Admin/ObjectDetail?NO_OBJ='+this.props.NO_OBJ,
                {headers:new Headers ({
                    'Access-Control-Allow-Origin' : '*',
                    'Authorization' : CookieService.get("access_token") 
                })}
            
            ) 
                .then(response => response.json())
                .then(dataJs => {
                    //console.log(dataJs);
                    this.setState({
                        dataJs: dataJs.Data
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            
            }
        }

          componentWillMount(){
          
          }

          changeHandler=(e: any)=>{
            let dataJs = this.state.dataJs;
            dataJs[e.target.name]= e.target.value;
            this.setState({dataJs: dataJs});
          }
          changeCheck=(e: any)=>{
            let dataJs = this.state.dataJs;
            dataJs[e.target.name]= e.target.checked;
            this.setState({dataJs: dataJs});
          }
          changeHTML=(e: any)=>{
            let dataJs = this.state.dataJs;
            dataJs["HTML"]= e;
            this.setState({dataJs: dataJs});
          }
          submitHandler = (e: any) =>{
            e.preventDefault();
            console.log(this.state.dataJs);
            fetch(
                configData.serviceUrl+ '/api/Admin/ObjectDetail',
                {
                    method: 'POST' ,
                    body: JSON.stringify(this.state.dataJs),
                    headers: new Headers ({
                    'Access-Control-Allow-Origin' : '*',
                    'Authorization' :  CookieService.get("access_token") ,
                    'Content-Type' : 'application/json'
                    })
                    
                }
            ) 
                .then(response => response.json())
                .then(dataJs => {
                    {
                       // window.location.reload();
                    }
                })
                .catch(e => {
                    console.log(e);
                });
          }

        
          // On file select (from the pop up)
        onFileChange = event => {
    
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
      
      };
      onFileBgChange = event => {
    
        // Update the state
        this.setState({ selectedBgFile: event.target.files[0] });
      
      };
          // On file upload (click the upload button)
         onFileUpload = () => {
            const formData = new FormData();
            // Update the formData object
            formData.append(
            "files",
            this.state.selectedFile
            );
            // Details of the uploaded file
            console.log(this.state.selectedFile);
            // Request made to the backend api
            // Send formData object
            fetch(
                configData.serviceUrl+ '/api/Admin/Upload?ModunName='+ this.props.ModunName,
                {
                    method: 'POST' ,
                    body: formData,
                    headers:new Headers ({
                    'Access-Control-Allow-Origin' : '*',
                    'Authorization' : CookieService.get("access_token") 
                   
                    })
                }
            
            )
            .then(response => response.json())
            .then(rss => {
                if(rss.Status===200)
                {
                    let dataJs = this.state.dataJs;
                    dataJs["TEXT1"]= rss.Data.NameFile;
                    this.setState({dataJs: dataJs, imageUrl: rss.Data.NameFile});
                    alert("Upload xong!");
                }
                else
                {
                    alert("Upload lỗi: " + rss.Message);
                }
            })
            .catch(e => {
                console.log(e);
            });
                
               
        };
        onFileBgUpload = () => {
            const formData = new FormData();
            // Update the formData object
            formData.append(
            "files",
            this.state.selectedBgFile
            );
            // Details of the uploaded file
            console.log(this.state.selectedBgFile);
            // Request made to the backend api
            // Send formData object
            fetch(
                configData.serviceUrl+ '/api/Admin/Upload?ModunName='+ this.props.ModunName,
                {
                    method: 'POST' ,
                    body: formData,
                    headers:new Headers ({
                    'Access-Control-Allow-Origin' : '*',
                    'Authorization' : CookieService.get("access_token") 
                   
                    })
                }
            
            )
            .then(response => response.json())
            .then(rss => {
                if(rss.Status===200)
                {
                    let dataJs = this.state.dataJs;
                    dataJs["TEXT7"]= rss.Data.NameFile;
                    this.setState({dataJs: dataJs, imageBackgroudUrl: rss.Data.NameFile});
                    alert("Upload xong!");
                }
                else
                {
                    alert("Upload lỗi: " + rss.Message);
                }
            })
            .catch(e => {
                console.log(e);
            });
                
               
        };
        // file upload is complete
        fileData = () => {
            
            if (this.state.selectedFile) {
            
            return (
                <div>
                <h2>Chi tiết file:</h2>
                        
                <p>File Name: {this.state.selectedFile.name}</p>
                <p>File Type: {this.state.selectedFile.type}</p>
                </div>
                 );
            } 
            else
             {
                 return (
                        <div>
                        <br />
                        <h4>Chọn file để upload</h4>
                        </div>
                    );
             }
        };
        fileBgData = () => {
            
            if (this.state.selectedBgFile) {
            
            return (
                <div>
                <h2>Chi tiết file:</h2>
                        
                <p>File Name: {this.state.selectedBgFile.name}</p>
                <p>File Type: {this.state.selectedBgFile.type}</p>
                </div>
                 );
            } 
            else
             {
                 return (
                        <div>
                        <br />
                        <h4>Chọn file để upload</h4>
                        </div>
                    );
             }
        };
        optionGroupData = () => {
            
            if (this.props.groupJs.length > 0 ) {
            
            return (
                <>
                    {
                    this.props.groupJs.map((item: any, index: number) => (
                        <option value={item.ID_OGR}>{item.NAME_OGR}</option>
                    ))
                    }
                </>
                 );
            } 
            else
             {
                 return (
                        <div>
                        </div>
                    );
             }
        };

          render(){

            const img1 = {
                width : '130px',
                height : '105px',
                resize : 'both'
              }  as const;

            return (
              <div   >
                 
                <Form>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Danh mục</Form.Label>
                        <Col sm="10">
                             <Form.Control name="ID_OGR" as='select' size="lg" value={this.state.dataJs.ID_OGR}  onChange={this.changeHandler}> 
                             {this.optionGroupData()}
                             </Form.Control>
                         </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Mã sản phẩm</Form.Label>
                        <Col sm="10"> <Form.Control  name="ID_OBJ" type="text" size="lg" defaultValue={this.state.dataJs.ID_OBJ}   onChange={this.changeHandler} />   </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Tên sản phẩm</Form.Label>
                        <Col sm="10"> <Form.Control  name="NAME_OBJ" type="text" size="lg" defaultValue={this.state.dataJs.NAME_OBJ}   onChange={this.changeHandler} />   </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Tóm tắt</Form.Label>
                        <Col sm="10"> <Form.Control name="NOTE" as="textarea" rows={3} size="lg" value={this.state.dataJs.NOTE}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Từ khóa</Form.Label>
                        <Col sm="10"> <Form.Control name="TEXT2" type="text" size="lg" defaultValue={this.state.dataJs.TEXT2}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Giá chuẩn</Form.Label>
                        <Col sm="10"> <Form.Control name="MONEY1" type="number" size="lg" defaultValue={this.state.dataJs.MONEY1}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                   
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Ảnh đại diện</Form.Label>
                        <Col sm="10"> 
                          
                            <img src={"/Upload/"+this.props.ModunName+"/"+this.state.imageUrl} style={img1}></img> 
                            <br/>
                            <br/>
                            <div>
                                    <input type="file" onChange={this.onFileChange} />
                                    <Button   onClick={this.onFileUpload}>
                                    Upload...
                                    </Button >
                            </div>
                            {this.fileData()}
                         </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">Ảnh nền</Form.Label>
                        <Col sm="10"> 
                          
                            <img src={"/Upload/"+this.props.ModunName+"/"+this.state.imageBackgroudUrl} style={img1}></img> 
                            <br/>
                            <br/>
                            <div>
                                    <input type="file" onChange={this.onFileBgChange} />
                                    <Button   onClick={this.onFileBgUpload}>
                                    Upload...
                                    </Button >
                            </div>
                            {this.fileBgData()}
                         </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                    <Col sm="12">  
                        <Editor  
                            tools={OriginalTools}
                            value={this.state.dataJs.HTML}
                            onChange={this.changeHTML}
                        />
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={this.submitHandler}  >
                        Lưu
                    </Button>
                    </Form>
              </div>
              
              );
          }

}

export default ChiTietSanPham;
