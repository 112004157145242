import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './SignUp.css';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from "../../../config.json";

import {Helmet} from 'react-helmet';
import CookieService from '../../../services/CookieService';
import { Editor, OriginalTools } from 'react-bootstrap-editor';
import {  Container, Row,Col, Form, Button } from 'react-bootstrap';


class GlobalConfig extends React.Component<any, any> {
  private dateValue:Date=new Date();
    constructor(props: any) {
        super(props);
        this.state = {
          dataJs: {},
          KhauHieu: '',
          BanQuyen: '',
          GoogleMap: '',
          ColorBackground: '',
          

          imageUrl: '',
          selectedFile: null,

          imageLogoUrl: '',
          selectedLogoFile: null
        };
        
    }

    componentDidMount() {
        fetch(
            configData.serviceUrl+ '/api/Common/GetConfig?ID_INF=99012',
            {headers:new Headers ({
                'Access-Control-Allow-Origin' : '*'
            })}
        ) 
            .then(response => response.json())
            .then(dataJs => {
                //console.log(dataJs);
                if( dataJs.Data.length>0)
                {
                  var listOrderJson = JSON.stringify(dataJs.Data);
                  JSON.parse(listOrderJson).forEach((item, index) => { 
                    if(item.ID==="KhauHieu")
                    {
                      this.setState({
                        KhauHieu: item.VALUE
                      })
                    }
                    else if(item.ID==="BanQuyen")
                    {
                      this.setState({
                        BanQuyen: item.VALUE
                      })
                    }
                    else if(item.ID==="GoogleMap")
                    {
                      this.setState({
                        GoogleMap: item.VALUE
                      })
                    }
                    else if(item.ID==="ImgLogo")
                    {
                      this.setState({
                        imageLogoUrl: item.VALUE
                      })
                    }
                    else if(item.ID==="ImgBanner")
                    {
                      this.setState({
                        imageUrl: item.VALUE
                      })
                    }
                    else if(item.ID==="ColorBackground")
                    {
                      this.setState({
                        ColorBackground: item.VALUE
                      })
                    }
                 } );
                }
            })
            .catch(e => {
                console.log(e);
            });

        fetch(
            configData.serviceUrl+ '/api/Common/GetCompanyInfo',
            {headers:new Headers ({
                'Access-Control-Allow-Origin' : '*'
            })}
        
        ) 
        .then(response => response.json())
        .then(dataJs => {
            console.log(dataJs);
            this.setState({
                dataJs: dataJs.Data
            });
        })
        .catch(e => {
            console.log(e);
        });
    }
    
    changeHandler=(e: any)=>{
      let dataJs = this.state.dataJs;
      if(e.target.name==="KhauHieu")
      {
        this.setState({KhauHieu: e.target.value});
      }
      else if(e.target.name==="BanQuyen")
      {
        this.setState({BanQuyen: e.target.value});
      }
      else if(e.target.name==="GoogleMap")
      {
        this.setState({GoogleMap: e.target.value});
      }
      else if(e.target.name==="ColorBackground")
      {
        this.setState({ColorBackground: e.target.value});
      }
      else{
        dataJs[e.target.name]= e.target.value;
        this.setState({dataJs: dataJs});
      }
    }

    submitHandler = (e: any) =>{
      e.preventDefault();
      var body=[
        {
            "ID":"KhauHieu",
            "VALUE": this.state.KhauHieu
        },
        {
            "ID":"BanQuyen",
            "VALUE": this.state.BanQuyen
        },
        {
            "ID":"GoogleMap",
            "VALUE": this.state.GoogleMap
        },
        {
            "ID":"ColorBackground",
            "VALUE": this.state.ColorBackground
        },
        {
            "ID":"ImgBanner",
            "VALUE": this.state.imageUrl
        },
        {
            "ID":"ImgLogo",
            "VALUE": this.state.imageLogoUrl
        }
     ]
      fetch(
          configData.serviceUrl+ '/api/Admin/SaveConfig?ID_INF=99012',
          {
              method: 'POST' ,
              body: JSON.stringify(body),      
              headers: new Headers ({
              'Access-Control-Allow-Origin' : '*',
              'Authorization' :  CookieService.get("access_token") ,
              'Content-Type' : 'application/json'
              })
              
          }
      ) 
          .then(response => response.json())
          .then(rss => {
            if(rss.Status===200 || rss.Status===201)
            {
              //window.location.reload();
            }
            else
            {
                alert("Có lỗi: " + rss.Message);
            }
          })
          .catch(e => {
              console.log(e);
          });

          //luu thong tin cong ty
          fetch(
            configData.serviceUrl+ '/api/Admin/ObjectDetail',
            {
                method: 'POST' ,
                body: JSON.stringify(this.state.dataJs),
                headers: new Headers ({
                'Access-Control-Allow-Origin' : '*',
                'Authorization' :  CookieService.get("access_token") ,
                'Content-Type' : 'application/json'
                })
                
            }
        ) 
            .then(response => response.json())
            .then(dataJs => {
                 
               // if(this.state.dataJs.NO_OBJ===0)
                {
                    window.location.reload();
                }
            })
            .catch(e => {
                console.log(e);
            });
      }

//file hình nền
      onFileUpload = () => {
        const formData = new FormData();
        // Update the formData object
        formData.append(
        "files",
        this.state.selectedFile
        );
        // Details of the uploaded file
        console.log(this.state.selectedFile);
        // Request made to the backend api
        // Send formData object
        fetch(
            configData.serviceUrl+ '/api/Admin/Upload?ModunName=TrangChu',
            {
                method: 'POST' ,
                body: formData,
                headers:new Headers ({
                'Access-Control-Allow-Origin' : '*',
                'Authorization' : CookieService.get("access_token") 
               
                })
            }
        
        )
        .then(response => response.json())
        .then(rss => {
            if(rss.Status===200)
            {
                this.setState({imageUrl: rss.Data.NameFile});
                alert("Upload xong!");
            }
            else
            {
                alert("Upload lỗi: " + rss.Message);
            }
        })
        .catch(e => {
            console.log(e);
        });
            
           
    };
    onFileChange = event => {
    
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    
    };
    fileData = () => {
            
      if (this.state.selectedFile) {
      
      return (
          <div>
          <h2>Chi tiết file:</h2>
                  
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          </div>
           );
      } 
      else
       {
           return (
                  <div>
                  <br />
                  <p>Chọn file để upload</p>
                  </div>
              );
       }
  };

//file logo
onFileLogoUpload = () => {
    const formData = new FormData();
    // Update the formData object
    formData.append(
    "files",
    this.state.selectedLogoFile
    );
    // Details of the uploaded file
    console.log(this.state.selectedLogoFile);
    // Request made to the backend api
    // Send formData object
    fetch(
        configData.serviceUrl+ '/api/Admin/Upload?ModunName=TrangChu',
        {
            method: 'POST' ,
            body: formData,
            headers:new Headers ({
            'Access-Control-Allow-Origin' : '*',
            'Authorization' : CookieService.get("access_token") 
           
            })
        }
    
    )
    .then(response => response.json())
    .then(rss => {
        if(rss.Status===200)
        {
            this.setState({imageLogoUrl: rss.Data.NameFile});
            alert("Upload Logo xong!");
        }
        else
        {
            alert("Upload logo lỗi: " + rss.Message);
        }
    })
    .catch(e => {
        console.log(e);
    });
        
       
};
onFileLogoChange = event => {

  // Update the state
  this.setState({ selectedLogoFile: event.target.files[0] });

};
fileLogoData = () => {
        
  if (this.state.selectedLogoFile) {
  
  return (
      <div>
      <h2>Chi tiết file:</h2>
              
      <p>File Name: {this.state.selectedLogoFile.name}</p>
      <p>File Type: {this.state.selectedLogoFile.type}</p>
      </div>
       );
  } 
  else
   {
       return (
              <div>
              <br />
              <p>Chọn file logo để upload</p>
              </div>
          );
   }
};


    render(){
      const img1 = {
        width : '780px',
        height : '646px',
        resize : 'both'
      }  as const;
      const img2 = {
        width : '150px',
        height : '33px',
        resize : 'both'
      }  as const;
       
      
        return (
        <div className="container" style={{ marginTop: "10px" , marginBottom: "10px" , width: "100%" }}>
          <Helmet>
						<title>Thiết lập chung</title>
					</Helmet>

          <div   >
                 
                 <Form> 
                   
                     <Form.Group as={Row} className="mb-3" >
                         <Form.Label column sm="2" >Tên công ty</Form.Label>
                         <Col sm="10"> <Form.Control  name="NAME_OBJ" type="text" size="lg" defaultValue={this.state.dataJs.NAME_OBJ}   onChange={this.changeHandler} />   </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3" >
                         <Form.Label column sm="2" >Tên giao dịch</Form.Label>
                         <Col sm="10"> <Form.Control  name="SHORTNAME" type="text" size="lg" defaultValue={this.state.dataJs.SHORTNAME}   onChange={this.changeHandler} />   </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3" >
                         <Form.Label column sm="2" >Khẩu hiệu</Form.Label>
                         <Col sm="10"> <Form.Control  name="KhauHieu" type="text" size="lg" defaultValue={this.state.KhauHieu}   onChange={this.changeHandler} />   </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Lĩnh vực hoạt động</Form.Label>
                        <Col sm="10"> <Form.Control name="NOTE" as="textarea" rows={3} size="lg" value={this.state.dataJs.NOTE}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Địa chỉ</Form.Label>
                        <Col sm="10"> <Form.Control name="TEXT9" type="text" size="lg" defaultValue={this.state.dataJs.TEXT9}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Email</Form.Label>
                        <Col sm="10"> <Form.Control name="TEXT6" type="text" size="lg" defaultValue={this.state.dataJs.TEXT6}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Số điện thoại</Form.Label>
                        <Col sm="10"> <Form.Control name="TEXT4" type="text" size="lg" defaultValue={this.state.dataJs.TEXT4}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Copyrights</Form.Label>
                        <Col sm="10"> <Form.Control name="BanQuyen" type="text" size="lg" defaultValue={this.state.BanQuyen}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >GoogleMaps</Form.Label>
                        <Col sm="10"> <Form.Control name="GoogleMap" as="textarea" rows={3} size="lg" value={this.state.GoogleMap}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Màu nền</Form.Label>
                        <Col sm="10"> <Form.Control name="ColorBackground" as="text" rows={3} size="lg" value={this.state.ColorBackground}  onChange={this.changeHandler}/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Logo Công Ty(150x33)</Form.Label>
                        <Col sm="10"> 
                          
                            <img src={"/Upload/TrangChu/"+this.state.imageLogoUrl} style={img2}></img> 
                            <br/>
                            <br/>
                            <div>
                                    <input type="file" onChange={this.onFileLogoChange} />
                                    <Button   onClick={this.onFileLogoUpload}>
                                    Upload...
                                    </Button >
                            </div>
                            {this.fileLogoData()}
                         </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Hình ảnh Banner(780x646)</Form.Label>
                        <Col sm="10"> 
                          
                        <img src={"/Upload/TrangChu/"+this.state.imageUrl} style={img1}></img> 
                            <br/>
                            <br/>
                            <div>
                                    <input type="file" onChange={this.onFileChange} />
                                    <Button   onClick={this.onFileUpload}>
                                    Upload...
                                    </Button >
                            </div>
                            {this.fileData()}
                         </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={this.submitHandler}  >
                        Lưu
                    </Button>
                    </Form>
              </div>
         </div>   
           
        );
    }

}

export default GlobalConfig;
