import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../home/stylePage.css'; 
 
import queryString from 'query-string';
 
import {  Container, Row,Col,Form } from 'react-bootstrap';
import configData from "../../config.json";

import BreadCrumbNews from '../Commons/BreadCrumbNews';
import BreadCrumbs from '../Commons/BreadCrumbs';
import ReCAPTCHA from "react-google-recaptcha";
import '../LienHe/LienHe.css';
import { BiWorld,BiPhoneCall   } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import {Helmet} from 'react-helmet'; 
import axios from 'axios';

class LienHe extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                        ID_OTY: 1,
                        ID_OGR: 0,
                        NO_OBJ: 0,
                        Model: {
                            CompanyName: "Công ty Cổ phần Tin học Sài Gòn",
                            CompanyAddress: "52 Bàu Cát 6, P. 14, Q. Tân Bình, TP. Hồ Chí Minh",
                            CompanyEmail : "erp@saigonit.com",
                            CompanyPhone:"(028) 38 100 500",
                        },
                        register : {
                            Email: '',
                            Name : '',
                            Message: '',
                            Token:''
                          },
                        notice: '',
                        dataJsCompany:{},
                        GoogleMap: ''
                }
          }
          componentDidMount() {
              const values = queryString.parse(this.props.location.search);

              this.setState({
                ID_OGR: values.m,
                NO_OBJ:values.NO_OBJ
              });

              fetch(
                configData.serviceUrl+ '/api/News/Footer',
                {headers:new Headers ({
                    'Access-Control-Allow-Origin' : '*'
                     
                })}
            
            ) 
                .then(response => response.json())
                .then(dataJs => {
                    //console.log(dataJs);
                    this.setState({
                        Model: dataJs.Data
                    });
                })
                .catch(e => {
                    console.log(e);
                });

                fetch(
                    configData.serviceUrl+ '/api/Common/GetConfig?ID_INF=99012',
                    {headers:new Headers ({
                        'Access-Control-Allow-Origin' : '*'
                    })}
                ) 
                    .then(response => response.json())
                    .then(dataJs => {
                        //console.log(dataJs);
                        if( dataJs.Data.length>0)
                        {
                          var listOrderJson = JSON.stringify(dataJs.Data);
                          JSON.parse(listOrderJson).forEach((item, index) => { 
                           if(item.ID==="GoogleMap")
                            {
                              this.setState({
                                GoogleMap: item.VALUE
                              })
                            }
                         } );
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });

                fetch(
                    configData.serviceUrl+ '/api/Common/GetCompanyInfo',
                    {headers:new Headers ({
                        'Access-Control-Allow-Origin' : '*'
                    })}
                
                  ) 
                  .then(response => response.json())
                  .then(dataJs => {
                      console.log(dataJs);
                      this.setState({
                          dataJsCompany: dataJs.Data
                      });
                  })
                  .catch(e => {
                      console.log(e);
                  });
            }

          componentWillMount(){
          
          }
          submitHandler = (e: any) =>{
            e.preventDefault();

            axios
            .post(configData.serviceUrl + '/api/Users/GuiLienHe', this.state.register)
            .then(response =>{
              if(response && response.status===200)
              {
                if(response.data.Status===202)
                {
                   this.setState( { notice : <label style={{ marginLeft: "5px", color:"Green" }}>Gửi Liên hệ thành công!</label>} );
                    //alert("Chúc mừng đăng ký thành công. Tiến hành đăng nhập");
                    //window.location.href= window.location.origin + '/login';
                }
                else  
                {
                  this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {response.data.Message}</label>} );
                  //alert(response.data.Message);
                }
              }
              else
              {
                 this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {"Không thể kết nối với API"}</label>} );
              }
            }
            ).catch(error =>{
              alert(error);
            })
          }
          changeHandler=(e: any)=>{
            let register = this.state.register;
            register[e.target.name]= e.target.value;
            this.setState({register: register});
          }
          onChange =(value)=> {
            // console.log(value );
             let register = this.state.register;
            register["Token"]= value;
            this.setState({register: register});
            //console.log(this.state.register);
          }

          render(){

            const iframStyle = {
                width : '100%',
               height : '450px',
               border:'0px',
                allowfullscreen: '',
                loading : 'lazy'
               
            }  as const;

            const div1 = {
                marginTop : '200px'
            }  as const;
            
            const {
                Email,
                Name ,
                Message,
                Token
             } = this.state.register;
             const notice= this.state.notice;
             const recaptchaRef = React.createRef();

            return (
              <div className="_container"  >
                   
                  <Helmet>
                    <title>{this.state.dataJsCompany.SHORTNAME+"- Liên hệ"} </title>
                    <meta name="description" content={this.state.dataJsCompany.NOTE+ ", Liên hệ"} />
                  </Helmet>
                 <section className="elementor-contact">
                   <div>
                   <BreadCrumbs ></BreadCrumbs>
                    </div>
                    <Container  >
                           <Row className="elementor-section">
                              <Col sm={6}  > 
                                 
                                   <div className="elementor-widget-wrap">
                                        <div >
                                            <div className="ot-heading">
                                                
                                                <h2 className="main-heading">Thông tin liên hệ</h2>
                                            </div>
                                        </div>
                                        <div  >
                                            <p>
                                                        {this.state.dataJsCompany.NOTE}
                                              </p>
                                          
                                        </div>
                                        <div >
                                            <div className="contact-info box-style1">
                                                <i className="flaticon-world-globe"><BiWorld /></i>
                                                <div className="info-text">
                                                <h6><b>Địa chỉ:</b></h6>
                                                <p>{this.state.dataJsCompany.TEXT9}</p>
                                                </div>
                                             </div>
                                        </div>
                                        <div >
                                            <div className="contact-info box-style1">
                                                <i className="flaticon-envelope"> <MdEmail></MdEmail></i>
                                                <div className="info-text">
                                                <h6><b>Email:</b></h6>
                                                <p>{this.state.dataJsCompany.TEXT6}</p>
                                                </div>
                                            
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-a59e635 elementor-widget elementor-widget-icontact_info" data-id="a59e635" data-element_type="widget" data-widget_type="icontact_info.default">
                                            
                                            <div className="contact-info box-style1">
                                                <i className="flaticon-phone-1"><BiPhoneCall></BiPhoneCall></i>
                                                <div className="info-text">
                                                <h6><b>Số điện thoại:</b></h6>
                                                <p>{this.state.dataJsCompany.TEXT4}</p>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                               </Col>
                               <Col sm={6}>
                                  <div className="elementor-widget-wrap">
                                    <form action="/" onSubmit={this.submitHandler}>
                                              <div className="main-form">
                                                  
                                                  <div className="ot-heading">
                                                
                                                <h2 className="main-heading">Gửi liên hệ</h2>
                                            </div>
                                                  <p className="font14">Email của bạn sẽ được bảo mật. Các trường bắt buộc nhập được đánh dấu *</p>
                                                <div className="form-group">
                                                   
                                                    <input name="Email" required value={Email} onChange={this.changeHandler} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nhập Email *" />
                                                   
                                                </div>
                                                <div className="form-group">
                                                   
                                                    <input name="Name" type="text" required value={ Name } onChange={this.changeHandler} className="form-control" id="txtName" placeholder="Nhập họ tên *" />
                                                </div>
                                                <div className="form-group">
                                                   
                                                    <textarea name="Message"  required value={ Message } onChange={this.changeHandler} className="form-control" id="txtMessage" placeholder="Nhập nội dung ..." />
                                                </div>
                                                
                                                <div className="form-group">
                                                    <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={ configData.GoogleReCAPTCHA_SiteKey }
                                                    onChange={this.onChange}
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-primary">Gửi</button>
                                                {notice}
                                                {/* <button type="button" className="btn btn-primary">Trang chủ</button> */}
                                                </div>
                                                </form>
                                     </div>
                                </Col>
                                    
                           </Row>
                          
                     </Container>
                     <Row className="elementor-section-maps">
                               <Col sm={12}>
                                  <div className="elementor-custom-embed">
                                  <iframe src={this.state.GoogleMap} style={iframStyle} ></iframe>
                                   </div>
                               </Col> 
                      </Row>
                  </section>
              </div>
              
              );
          }

}

export default LienHe;
