import React from 'react';
import { BrowserRouter as Router, Route, Link, BrowserRouter } from "react-router-dom";
import Login from '../login/Login';
import SignUp from '../sign_up/SignUp';
import LossPass from '../login/LossPass';
import LossPassSuccess from '../login/LossPassSuccess';
import Active from '../login/Active';
import User from '../user/user';
import TrangChu from '../TrangChu/TrangChu';
import GioiThieu from '../GioiThieu/GioiThieu';
import TinTuc from '../TinTuc/TinTuc';
import SanPham from '../SanPham/SanPham';
import ThuVien from '../ThuVien/ThuVien';
import ThongBao from '../ThongBao/ThongBao';
import LienHe from '../LienHe/LienHe';
import ViewCommon from '../Commons/ViewCommon';
import HomeBody from '../TrangChu/HomeBody';
import GioHang from '../GioHang/GioHang';
import DonHang from '../DonHang/DonHang';
import ChiTietDonHang from '../DonHang/ChiTietDonHang';
import AdminTinTuc from '../Admin/TinTuc/TinTuc';



class RouterUrl extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render(){
    return (
      <BrowserRouter>
        <Router>
          <Route path="/DangNhap" component={Login} />
          <Route exact path="/" component={HomeBody} >
          </Route>
          <Route path="/DangKy" component={SignUp} />
          <Route path="/QuenMatKhau" component={LossPass} />
          <Route path="/XacNhanEmail" component={LossPassSuccess} />
          <Route path="/KichHoat" component={Active} />
          <Route path="/user" component={User} />
          <Route path="/TrangChu" component={TrangChu} />
          <Route path="/ToChuc" component={GioiThieu} />
          <Route path="/GioiThieu" component={GioiThieu} />
          <Route path="/TinTuc" component={TinTuc} />
          <Route path="/SanPham" component={SanPham} />
          <Route path="/ThuVien" component={ThuVien} />
          <Route path="/ThongBao" component={ThongBao} />
          <Route path="/HoTro" component={ViewCommon} />
          <Route path="/LienHe" component={LienHe} />
          <Route path="/GioHang" component={GioHang} />
          <Route path="/DonHang" component={DonHang} />
          <Route path="/ChiTietDonHang" component={ChiTietDonHang} />
          <Route path="/AdminTinTuc" component={AdminTinTuc }/>  
          </Router>
      </BrowserRouter>
      
  );
}
}
export default RouterUrl;
