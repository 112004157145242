import React from 'react';
import '../SanPham/product.css';
import ProductHeader from './ProductHeader';
import ProductBody from './ProductBody';
import ProductBodyImg from './ProductBodyImg';
import ProductOrder from './ProductOrder';
import configData from '../../config.json';
import queryString from 'query-string';
import UrlService from '../../services/UrlService';
import {Helmet} from 'react-helmet'; 

class Product extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
        this.state = {
			dataJs: [],
            dataJsCompany:{}
		}
	}
	componentDidMount() {
        const query= queryString.parse(window.location.search);
        
        fetch(
			configData.serviceUrl+ '/api/Products/GetProduct?NO_OBJ=' + query.id + '&KeySign=' + query.KeySign,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*'
			})}
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
            });

            fetch(
                configData.serviceUrl+ '/api/Common/GetCompanyInfo',
                {headers:new Headers ({
                    'Access-Control-Allow-Origin' : '*'
                })}
            
              ) 
              .then(response => response.json())
              .then(dataJs => {
                  console.log(dataJs);
                  this.setState({
                      dataJsCompany: dataJs.Data
                  });
              })
              .catch(e => {
                  console.log(e);
              });
      }
	componentWillMount(){
	}
    render(){
        const pro= this.state.dataJs;
        console.log(pro);
        return (
            <div className="container">
                
                <Helmet>
                    <title>{this.state.dataJsCompany.SHORTNAME+"- Sản phẩm "} {pro.NAME_OBJ}</title>
                    <meta name="description" content={this.state.dataJsCompany.NOTE + ", "+ pro.NAME_OBJ} />
                  </Helmet>
                <ProductHeader Published= { pro.ID_OBJ } 
                    Category={ pro.NAME_OGR }
                    Client={ pro.NAME_OBJ } />
                <ProductBodyImg src={ pro.ImageBgUrl? UrlService.UrlRoot() + pro.ImageBgUrl : UrlService.UrlRoot() +"/assets/img/product_detail.jpg" }/>
                <ProductOrder ProductCode={ pro.ID_OBJ } ProductName={ pro.NAME_OBJ } ProductPrice={ pro.MONEY1 } />
                <ProductBody Content={pro.HTML }/>
		    </div>
        );
    }
}
export default Product;

