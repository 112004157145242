import React from 'react';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import UrlService from '../../services/UrlService';
import {Helmet} from 'react-helmet'; 

class LossPass extends React.Component<any, any> {
  private dateValue:Date=new Date();
  private pass2Style;
    constructor(props: any) {
        super(props);
        this.state = {
          loss : {
            Email: '',
            Token:''
          },
            notice: any,
            dataJsCompany:{}
        };
        
    }
    
      changeHandler=(e: any)=>{
        let loss = this.state.loss;
        loss[e.target.name]= e.target.value;
        this.setState({loss: loss});
      }
      submitHandler = (e: any) =>{
        e.preventDefault();
        // console.log(this.state.register)
        axios
        .post(configData.serviceUrl + '/api/Users/ForgotPassword', this.state.loss)
        .then(response =>{
          if(response && response.status===200)
          {
            if(response.data.Status===202)
            {
              window.location.href= UrlService.UrlRoot() + "/XacNhanEmail";
                //this.setState( { notice : <label style={{ marginLeft: "5px", color:"Green" }}>Lấy lại mật khẩu thành công. Kiểm tra email để kích hoạt tài khoản.</label>} );
                //alert("Chúc mừng đăng ký thành công. Tiến hành đăng nhập");
                //window.location.href= window.location.origin + '/login';
            }
            else  
            {
              this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {response.data.Message}</label>} );
              //alert(response.data.Message);
            }
        }
        else
        {
            this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {"Không thể kết nối với API"}</label>} );
        }
    }
        ).catch(error =>{
          alert(error);
        })
      }
      onChange =(value)=> {
        // console.log(value );
         let loss = this.state.loss;
         loss["Token"]= value;
        this.setState({loss: loss});
        //console.log(this.state.register);
      }
      componentDidMount() {
        fetch(
            configData.serviceUrl+ '/api/Common/GetCompanyInfo',
            {headers:new Headers ({
                'Access-Control-Allow-Origin' : '*'
            })}
        
          ) 
          .then(response => response.json())
          .then(dataJs => {
              console.log(dataJs);
              this.setState({
                  dataJsCompany: dataJs.Data
              });
          })
          .catch(e => {
              console.log(e);
          });
      }
    render(){
      const {
         Email,
         Token
      } = this.state.loss;
      const notice= this.state.notice;
      const recaptchaRef = React.createRef();
        return (
        <div className="container" style={{ marginTop: "92px" , minHeight:"68vh", marginBottom: "20px" , width: "800px" }}>
          
          <Helmet>
                    <title>{this.state.dataJsCompany.SHORTNAME+' - Quên mật khẩu'}</title>
                    <meta name="description" content={this.state.dataJsCompany.NOTE} />
                  </Helmet>

            <form action="/" onSubmit={this.submitHandler}>
              <h1>Khôi phục mật khẩu</h1>
              <p>Vui lòng cung cấp email đăng nhập để khôi phục mật khẩu của bạn.</p>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email</label>
                <input name="Email" required value={Email} onChange={this.changeHandler} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nhập Email" />
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
              <div className="form-group">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  
                  sitekey={ configData.GoogleReCAPTCHA_SiteKey }
                  onChange={this.onChange}
                />
              </div>
              <button type="submit" className="btn btn-primary">Lấy lại mật khẩu</button>
              {notice}
              {/* <button type="button" className="btn btn-primary">Trang chủ</button> */}
            </form>
         </div>   
           
        );
    }

}

export default LossPass;
