import React from 'react';
import configData from "../../config.json";
import ImageView from '../Commons/ImageView';
import ProductCard from '../Commons/ProductCard';
import {  Container, Row,Col } from 'react-bootstrap';
import ProductsItem from './ProductsItem';
import equal from 'fast-deep-equal'

class ProductsList extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: []
		}
	}

	componentDidMount() {
		 
		this.LoadData();
    }

	componentDidUpdate(prevProps) {
		if(!equal(this.props.ID_OGR, prevProps.ID_OGR)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
		{
			this.componentDidMount();
		}
	} 

	componentWillMount(){
		 
	}

	LoadData(){
		var ID_OGR=this.props.ID_OGR;
		if (typeof ID_OGR === "undefined")
		{
			ID_OGR=0;
		}
		if(ID_OGR===0){
			ID_OGR=-2147483648;
		}

        fetch(
			configData.serviceUrl+ '/api/Products/GetProducts?ID_OGR='+ID_OGR,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*'
			})}
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
            });
	}

    render(){
		const dataJs =  this.state.dataJs;
		console.log(dataJs);
        return (
			<div id="portfolio-grid" className="row no-gutter" style={{ minHeight:"700px" , width: "99%", margin:"auto" }} data-aos="fade-up" data-aos-delay={200}>
				{
                    	dataJs.map((link:any) => 
						<ProductsItem key={ link.NO_OBJ.toString() } ID_OGRP={ link.ID_OGRP } ID_OGR={ link.ID_OGR } Url={ "SanPham/?m="+ link.ID_OGR + "&id="+ link.NO_OBJ +"&KeySign=" + link.KeySign } 
							Name={ link.NAME_OBJ } ImageUrl={ link.ImageUrl===""? "/Upload/SanPham/VAPro2010.jpg" : link.ImageUrl} Description={ link.ID_OBJ }
						/> )
				} 
      		</div>
        );
    }
}
export default ProductsList;
