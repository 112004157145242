import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import '../../home/Home.css';
//import '../../home/stylePage.css'; 

import NhomTinTucList from '../TinTuc/NhomTinTucList'

import queryString from 'query-string';
import { Col, Container, Row } from 'react-bootstrap';
 
 

class NhomTinTuc extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                ID_OTY: 9010 
                
              }
          }
          componentDidMount() {
              
            }

          componentWillMount(){
          
          }


          render(){

            const div1 = {
              paddingLeft : '10px'
          
            }  as const;
            
            
            return (
              <div className="container"  >
                 <section className="inner-page">
                 
                    <Container>
                        <Row>
                          <Col sm={12}> 
                          <NhomTinTucList ID_OTY={this.state.ID_OTY} ModunName="GioiThieu"/>
                          </Col>
                        </Row>
                    </Container>
                  </section>
              </div>
              
              );
          }

}

export default NhomTinTuc;
