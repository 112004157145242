import React from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import configData from "../../config.json";
import { Container, Row } from 'react-bootstrap';
import LastNews from './LastNews';
import LastNewsFullWidth from './LastNewsFullWidth';

class LastNewsMaster extends React.Component<any, any> {
	constructor(props:any) {
        super(props);
		this.state = {
			lstGroup :[
				{
				  ORDER_OGR: 0,
				  NAME_OGR: "",
				  ID_OTY: 0,
				  ID_OGR: 0
				}
			  ],
		}
	}
	componentDidMount() {
        fetch(configData.serviceUrl + '/api/Common/GetListGroupLastNew?ID_OTY='+ this.props.ID_OTY,
        {headers:new Headers ({
            'Access-Control-Allow-Origin' : '*'
        })}
        )
            .then(response => response.json())
            .then(lstGroup => this.setState({lstGroup}))
            .catch(e => {
                console.log(e);
                return e;
            });
      }
	componentWillMount(){
	}
    render(){
		const lstGroup =  this.state.lstGroup;
        return (
            <div>
            {
                lstGroup.map((link:any, index) => 
                // <LastNews key={ link.ID_OGR.toString() } ID_OTY={ link.ID_OTY } ID_OGR={ link.ID_OGR } NAME_OGR={ link.NAME_OGR } LINK_GROUP={ link.LINK_GROUP } ImageUrl={ link.ImageUrl } />)
                <LastNewsFullWidth STT={(this.props.LeftFirst=="false")?index+1: index} key={ link.ID_OGR.toString() } ID_OTY={ link.ID_OTY } ID_OGR={ link.ID_OGR } NAME_OGR={ link.NAME_OGR } LINK_GROUP={ link.LINK_GROUP } ImageUrl={ link.ImageUrl }   />)
            }
            </div>
        );
    }

}

export default LastNewsMaster;
