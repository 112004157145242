import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import '../../home/Home.css';
//import '../../home/stylePage.css'; 

import DanhSachTinTuc from '../TinTuc/DanhSachTinTuc'

import queryString from 'query-string';
 
import {  Container, Row,Col } from 'react-bootstrap';


class GioiThieu extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                ID_OTY: 9008,
                ID_OGR: 0,
                NO_OBJ: 0
              }
          }
          componentDidMount() {
              const values = queryString.parse(this.props.location.search);

              this.setState({
                ID_OGR: values.m,
                NO_OBJ:values.NO_OBJ
              });
            }

          componentWillMount(){
          
          }


          render(){

            const div1 = {
              paddingLeft : '10px'
          
            }  as const;
            
            let rs;
            if(this.state.NO_OBJ ===0 || this.state.NO_OBJ===null || this.state.NO_OBJ===undefined)
            {
              rs= <DanhSachTinTuc ID_OTY={this.state.ID_OTY} ModunName="ThongBao" />
            }
            else 
            {
                rs= <DanhSachTinTuc ID_OTY={this.state.ID_OTY} ModunName="ThongBao" />
            }
            return (
              <div className="container"  >
                 <section className="inner-page">
                 
                    <Container>
                        <Row>
                          <Col sm={12}> 
                          {rs}
                          </Col>
                         
                        </Row>
                    </Container>
                  </section>
              </div>
              
              );
          }

}

export default GioiThieu;
